import React, { useState } from "react";
import styles from "../../assets/styles/layout.module.css";
import logo from "../../assets/images/layout/sidebarlogo.png";
import plusLogo from "../../assets/images/layout/pluslogo.png";
import SidebarData from "./SidebarData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { DropDownIcon } from "../../utils/functions/Icons";
import { handleToggle } from "../../utils/store/common/CommonSlice";

const Sidebar = () => {
    const { sidebarToggle } = useSelector((state: RootState) => state.common);
    const location = useLocation();
    const navigate = useNavigate();
    const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
    const dispatch = useDispatch<AppDispatch>();
    const handleItemClick = (index: number, item: any) => {
        setOpenItems((prevOpenItems) => {
            const newOpenItems: { [key: number]: boolean } = {};
            Object.keys(prevOpenItems).forEach((key) => {
                newOpenItems[Number(key)] = false; 
            });

            if (item?.children) {
                newOpenItems[index] = !prevOpenItems[index]; 
                if (window.innerWidth <= 768) {
                    dispatch(handleToggle(false));
                }
            } else {
                navigate(item.path);
                if (window.innerWidth <= 768) {
                    dispatch(handleToggle(true));
                }
            }

            return newOpenItems;
        });
    };

    const handleChildNavigate =(subItem: SidebarDataType) => {
        navigate(subItem.path)
        if (window.innerWidth <= 768) {
            dispatch(handleToggle(true));
        }
    }

    const isActive = (item: any) => {
        return location.pathname.startsWith(item.path);
    };

    return (
        <div className={`${styles.sidebarMain} ${sidebarToggle ? styles.toggle : ''}`}>
            <div className={`${sidebarToggle ? styles.sidebarLogoToggle : styles.sidebarLogo}`}>
                <img src={sidebarToggle ? plusLogo : logo} alt="logo" />
            </div>
            <ul className={styles.sidebarItemsMain}>
                {
                    SidebarData?.map((item, index) => {
                        const isOpen = openItems[index] || false;
                        const isParentActive = isActive(item);
                        const activeChild = item?.children?.find((subItem: any) => location.pathname.startsWith(subItem.path));

                        return (
                            <li key={index} className={`${isParentActive || isOpen ? styles.ActiveLink : ''}`}>
                                <div className={styles.sidebarItemsMainDiv} onClick={() => handleItemClick(index, item)}>
                                    <div className={styles.sidebarItemsDiv}>
                                        <div className={styles.sidebarIcon}>
                                            {item?.icon && <item.icon />}
                                        </div>
                                        <span>{item?.title}</span>
                                    </div>
                                    {item?.children && <span className={styles.DropDownIcon}><DropDownIcon /></span>}
                                </div>
                                {item?.children && isOpen &&
                                    <ul className={styles.childItemsMain}>
                                        {item?.children?.map((subItem, id) => (
                                            <li 
                                                key={id} 
                                                className={`${styles.childItems} ${location.pathname.startsWith(subItem.path) ? styles.childActive : ''}`} 
                                                onClick={() => handleChildNavigate(subItem)}
                                            >
                                                <Link to={subItem.path}>{subItem?.title}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default Sidebar;

import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/dashboard.module.css"
import { DashboardArrowIcon } from '../../utils/functions/Icons'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getDashboardApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'
import { useNavigate } from 'react-router-dom'

interface ApiResponse {
    success: boolean;
    message: string;
    [key: string]: any;
}

const Dashboard = () => {
    const [loading, setLoading] = useState(false)
    const [dashboardData, setDashboardData] = useState<DashboardData | null | ApiResponse>(null)
    const navigate = useNavigate();
    const fetchDashboardData = async () => {
        try {
            setLoading(true)
            const result = await getDashboardApi();
            if (result?.success) {
                setDashboardData(result)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchDashboardData();
    }, [])

    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Dashboard</h2>
                </div>
                <div className={styles.dashboardMain}>
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-6'>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/patient/AllPatient')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <h3>All patients</h3>
                                    <p>{dashboardData?.totalMyPatientCount ? dashboardData?.totalMyPatientCount : 0}</p>
                                </div>
                            </div>
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/patient/MyPatient')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <h3>My patients</h3>
                                    <p>{dashboardData?.totalMyPatientCount ? dashboardData?.totalMyPatientCount : 0}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.cards}>
                            <div className={styles.ArrIcon} onClick={() => navigate('/patient/MyPatient')}>
                                <DashboardArrowIcon />
                            </div>
                            <div className={styles.cardContent}>
                                <h3>Newly assigned patients</h3>
                                <p>{dashboardData?.totalPatientCount ? dashboardData?.totalPatientCount : 0}</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/chat/patient')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <h3>Patient Chats <br /> Pending</h3>
                                    <p>{dashboardData?.chatCount ? dashboardData?.chatCount : 0}</p>
                                </div>
                            </div>
                            <div className={styles.cards}>
                                <div className={styles.ArrIcon} onClick={() => navigate('/chat/doctor')}>
                                    <DashboardArrowIcon />
                                </div>
                                <div className={styles.cardContent}>
                                    <h3>Doctor Chats <br /> Pending</h3>
                                    <p>{dashboardData?.chatCount ? dashboardData?.chatCount : 0}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.cards} ${styles.rpmCard}`}>
                            <div className={styles.ArrIcon} onClick={() => navigate('/RPMAlert')}>
                                <DashboardArrowIcon color='#B3261E' />
                            </div>
                            <div className={styles.cardContent}>
                                <h3>RPM Alerts</h3>
                                <p>{dashboardData?.rpmCount ? dashboardData?.rpmCount : 0}</p>
                            </div>
                        </div>
                        {
                            dashboardData?.appointments?.length > 0 &&
                            <div className={`${styles.dlCard}`}>
                                <div className={styles.dlMain}>
                                    <div className={styles.headStatus}>
                                        <h3>Doctor appointment</h3>
                                        <button className={styles.upcomingBtn}>Upcoming</button>
                                    </div>
                                    <span onClick={() => navigate('/Appointments')} className='cursor-pointer' >
                                        <DashboardArrowIcon />
                                    </span>
                                </div>
                                <div className='mt-4'>
                                    <TableContainer component={Paper} className='TableContainer'>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='dashboardTableStyle'>
                                            <TableHead className='custumTableHead dashboardTableHead'>
                                                <TableRow className={styles.completeTableRow}>
                                                    <TableCell>Patient Name</TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    dashboardData?.appointments?.map((item: DAppointment, index: number) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                className='custumTableRow dashboardTableBody'
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {item?.patientName}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.date}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.time}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        }
                        {
                            dashboardData?.labTests?.length > 0 &&
                            <div className={`${styles.dlCard}`}>
                                <div className={styles.dlMain}>
                                    <div className={styles.headStatus}>
                                        <h3>Lab Test Requests</h3>
                                        <button className={styles.upcomingBtn}>Pending</button>
                                    </div>
                                    <span onClick={() => navigate('/LabTests')} className='cursor-pointer' >
                                        <DashboardArrowIcon />
                                    </span>
                                </div>
                                <div className='mt-4'>
                                    <TableContainer component={Paper} className='TableContainer'>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='dashboardTableStyle'>
                                            <TableHead className='custumTableHead dashboardTableHead'>
                                                <TableRow className={styles.completeTableRow}>
                                                    <TableCell>Patient Name</TableCell>
                                                    <TableCell>Lab</TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    dashboardData?.labTests?.map((item: DLabTest, index: number) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                className='custumTableRow dashboardTableBody'
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {item?.patientName}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.labName}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.bookingDate}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.bookingTime}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default Dashboard
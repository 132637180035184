import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/chat.module.css"
import UseSocket from '../../utils/functions/useSocket'
import { getChatHistoryApi, getChatSearchListApi, getChatUserListApi, updateChatStatusApi, uploadFileApi } from '../../utils/api/services'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import { toast } from 'react-toastify'
import DoctorChatBody from '../../components/chat/DoctorChatBody'
import DoctorChatSidebar from '../../components/chat/DoctorChatSidebar'

const DoctorChat = () => {
  const [messages, setMessages] = useState<ChatHistoryItem[] | []>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [userList, setUserList] = useState<ChatPartner[] | []>([])
  const { LoginData } = useSelector((state: RootState) => state.auth)
  const [particularUser, setParticularUser] = useState<ChatPartner | null>(null)
  const [searchValue, setSearchValue] = useState('');
  const { connect, disconnect, sendMessage, listenForEvent } = UseSocket();
  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  const IntiatChat = async () => {
    try {
      if (LoginData?.id) {
        await sendMessage('listen', { userId: LoginData?.id, userType: 3 });
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    listenForEvent(`chatList`, (data: ChatListSocket) => {
      if (data?.senderId === LoginData?.id && data?.senderType === 3) {
        // setUserList(data?.senderChatList);
        const _List = data?.senderChatList?.filter((item: ChatPartner) => item?.chatPartnerType === 2)
        setUserList(_List);
      }
      if (data?.receiverId === LoginData?.id && data?.receiverType === 3 ) {
        // setUserList(data?.receiverChatList);
        const _List = data?.receiverChatList?.filter((item: ChatPartner) => item?.chatPartnerType === 2)
        setUserList(_List);
      }
    });

    listenForEvent(`chatHistory`, (data: ChatHistorySocket) => {
      if (data?.senderId === LoginData?.id && data?.senderType === 3 && data?.receiverId === particularUser?.chatPartnerId) {
        setMessages(data?.senderChatHistory);
      }
      if (data?.receiverId === LoginData?.id && data?.receiverType === 3 && data?.senderId === particularUser?.chatPartnerId) {
        setMessages(data?.receiverChatHistory);
      }
    });
    IntiatChat();
  }, [listenForEvent])

  const fetchApiUrl = async (url: string) => {
    try {
      const result = await uploadFileApi({ file: url, type: 'chat' })
      if (result?.success) {
        return result?.fileUrl
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }
  const handleSendMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const sendData = {
        senderId: LoginData?.id,
        senderType: 3,
        receiverId: particularUser?.chatPartnerId,
        receiverType: 2,
        message: newMessage,
        messageType: newMessage !== '' ? 1 : 2,
      }
      try {
        await sendMessage('sendMessage', sendData);
      } catch (error: any) {
        console.error(error.message);
      }
      setNewMessage('');
    }
  };

  const fetchUpdateReadStatus = async (data: {
    senderId: number;
    receiverId: number;
    senderType: number;
    receiverType: number;
  }) => {
    try {
      const result = await updateChatStatusApi(data);
      if (result?.success) {
        if (LoginData?.id) {
          if (searchValue) {
            fetchSearchUserList({ type: 2, search: searchValue })
          } else {
            fetchUserList({ userId: LoginData?.id, userType: 3, search: searchValue ? searchValue : undefined });
          }
        }

      }
    } catch (error) {

    }
  }
  const fetchChatHistory = async (data: { senderId: number, receiverId: number, senderType: number, receiverType: number }) => {
    try {
      const result = await getChatHistoryApi(data);
      if (result?.success) {
        setMessages(result?.chatHistory)
      }
      else {
        setMessages(result?.chatHistory)
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }


  const fetchUserList = async (data: { userId: number, userType: number, search: string | undefined }) => {
    try {
      const result = await getChatUserListApi(data);
      if (result?.success) {
        const _List = result?.chatList?.filter((item: ChatPartner) => item?.chatPartnerType === 2)
        setUserList(_List);
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }
  const fetchSearchUserList = async (data: { type: number, search: string }) => {
    try {
      const result = await getChatSearchListApi(data);
      if (result?.success) {
        const _List = result?.list?.filter((item: ChatPartner) => item?.chatPartnerType === 2)
        setUserList(_List);
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }
  useEffect(() => {
    if (LoginData?.id) {
      if (searchValue) {
        const ClearFunction = setTimeout(() => {
          fetchSearchUserList({ type: 2, search: searchValue })
        }, 1000);
        return () => clearTimeout(ClearFunction)
      }
      else {
        fetchUserList({ userId: LoginData?.id, userType: 3, search: undefined });
      }
    }
  }, [searchValue])

  const handleParticularUser = async (data: ChatPartner) => {
    setParticularUser(data)
    setNewMessage('')
    if (LoginData?.id) {
      if (data?.unreadMessages !== '0') {
        fetchUpdateReadStatus({ senderId: LoginData?.id, receiverId: data?.chatPartnerId, senderType: 3, receiverType: 2 })
      }
      fetchChatHistory({ senderId: LoginData?.id, receiverId: data?.chatPartnerId, senderType: 3, receiverType: 2 });
    }
  }
  const handleChangeMsg = (e: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value)
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0] ?? null;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Data = reader.result as string;
        if (base64Data) {
          const fileUrl = await fetchApiUrl(base64Data)
          const sendData = {
            senderId: LoginData?.id,
            senderType: 3,
            receiverId: particularUser?.chatPartnerId,
            receiverType: 2,
            message: fileUrl?.fileUrl,
            messageType: 2,
          }
          try {
            await sendMessage('sendMessage', sendData);
          } catch (error: any) {
            console.error(error.message);
          }
          setNewMessage('');
        }
      };
      reader.onerror = (error) => console.error("Error reading file:", error);
    }
  }

  return (
    <CustomContainer>
      <div className='AllPageMain'>
        <div className='HeadAndButton'>
          <h2>Chat With Doctor</h2>
        </div>
        <div className={styles.main}>
          <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-6 gap-y-6'>
            <div className='lg:col-span-1'>
              <DoctorChatSidebar
                setSearchValue={setSearchValue}
                handleParticularUser={handleParticularUser}
                userList={userList}
                searchValue={searchValue}
                particularUser={particularUser}
              />
            </div>
            <div className={`lg:col-span-3`}>
              <DoctorChatBody
                handleFileChange={handleFileChange}
                handleSendMessage={handleSendMessage}
                newMessage={newMessage}
                handleChangeMsg={handleChangeMsg}
                messages={messages}
                particularUser={particularUser}
              />
            </div>
          </div>
        </div>
      </div>
    </CustomContainer>
  )
}

export default DoctorChat
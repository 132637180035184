import React, { FormEvent, useState } from 'react'
import styles from "../assets/styles/login.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material'
import { MailIcon } from '../utils/functions/Icons'
import logo from "../assets/images/login/logo.svg"
import logo2 from "../assets/images/layout/sidebarlogo.png"
import girlImg from "../assets/images/login/groupimg.png"
import { useNavigate } from 'react-router-dom'
import { forgotPasswordApi } from '../utils/api/services'
import { toast } from 'react-toastify'
import BtnLoader from '../components/common/BtnLoader'

const ForgotPassword = () => {
    const [email, setEmail] = useState<string>('')
    const [btnLoading, setBtnLoading] = useState(false);

    const navigate = useNavigate();
    const handleChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e?.target?.value)
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setBtnLoading(true)
            const result = await forgotPasswordApi({ email: email })
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                navigate('/otp', {
                    state: email
                })
            } else { toast.error(result?.message) }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <div className={styles.loginMain}>
            <div className={`${styles.logo} ${styles.showOnlyOnMobile}`}>
                <img src={logo2} alt="logo" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-7 gap-6 h-full">
                <section className={`col-span-4 ${styles.bannerSection}`}>
                    <div className={styles.logo}>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className={styles.girlImg}>
                        <img src={girlImg} alt="girl image" />
                    </div>
                </section>
                <section className={`col-span-3 ${styles.formSection}`}>
                    <div className={styles.formBox}>
                        <div className={styles.loginHead}>
                            <h4>Forgot Password?</h4>
                        </div>
                        <form noValidate={false} onSubmit={handleSubmit}>
                            <FormGroup className="customFormGroup">
                                <TextField
                                    className={`custumTextField ${styles.InputStyle}`}
                                    id="email"
                                    type='email'
                                    name="email"
                                    onChange={handleChangeLogin}
                                    required
                                    value={email}
                                    placeholder='Work Email address'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormGroup>
                            <button
                                type='submit'
                                className='fillButton w-full mt-8'
                                disabled={btnLoading}
                            >  {
                                    btnLoading &&
                                    <BtnLoader />
                                }
                                Send OTP
                            </button>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ForgotPassword
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { fetchCompletedAppReducer } from '../../utils/store/appointment/AppointmentSlice';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { handleViewDocument } from '../../utils/store/common/CommonSlice';
import DocumentViewModal from '../savedDocuments/DocumentViewModal';
import { Images } from '../../utils/functions/Images';
import { toast } from 'react-toastify';
import { uploadFileApi, UploadPrescriptionLabApi } from '../../utils/api/services';
import UploadLabReportModal from '../labtest/UploadLabReportModal';

export default function Completed() {
    const [year, setYear] = useState<string | number>("0");
    const [searchValue, setSearchValue] = useState('')
    const [particularData, setParticularData] = useState<appointmentTypes | null>(null)
    const [btnLoading, setBtnLoading] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [file, setFile] = useState('');
    const { loading, CompletedAppointment, } = useSelector((state: RootState) => state.appointment)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2, filter: year }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2, filter: year }));
        }
    }, [searchValue, year]);

    const handleViewPrescription = (e: React.MouseEvent, item: appointmentTypes) => {
        e.stopPropagation();
        if (item?.prescriptionURL?.endsWith('.pdf')) {
            dispatch(handleViewDocument({
                dateTime: item?.dateTime,
                doctorName: item?.doctorName,
                experience: item?.experience,
                prescriptionURL: item?.prescriptionURL ? item?.prescriptionURL : '',
                speciality: item?.speciality
            }))
        }
        else {
            if (item?.prescriptionURL) {
                setParticularData(item)
                setOpenConfirmModal(true)
            }
        }
    }

    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
        setFile('')
    }
    const fetchApiUrl = async () => {
        try {
            const result = await uploadFileApi({ file: file, type: 'report' })
            if (result?.success) {
                return result?.fileUrl
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const confirmSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const _fileData = await fetchApiUrl();
            if (_fileData) {
                setBtnLoading(true)
                const result = await UploadPrescriptionLabApi({ patientId: particularData?.patientId, doctorId: particularData?.doctorId, appointmentId: particularData?.appointmentId, prescriptionURL: _fileData?.fileUrl });
                if (result?.success) {
                    setBtnLoading(false)
                    dispatch(fetchCompletedAppReducer({ search: searchValue || '', id: 2, filter: year }));
                    setFile('')
                    CloseConfirmModal();
                }
                else {
                    setBtnLoading(false)
                    toast.error(result?.message)
                }
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const handleFileChange = async (name: string, file: File | null) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64Data = reader.result as string;
                setFile(base64Data)
            };
            reader.onerror = (error) => console.error("Error reading file:", error);
        }
    };


    return (
        <div>
            <div className={'mb-6 flex justify-between items-center flex-col md:flex-row w-full max-w-full gap-y-4 md:gap-y-0'}>
                <FormGroup className="customFormGroup md:w-36 w-full">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search by doctor or specialising in'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <FormGroup className="customFormGroup md:w-36 w-full">
                    <Select
                        className={`custumTextField profileInput`}
                        placeholder="Date"
                        defaultValue={"0"}
                        value={year}
                        onChange={(e) => setYear(e.target?.value)}
                    >
                        <MenuItem selected value="0">All</MenuItem>
                        <MenuItem value="1">Last Month</MenuItem>
                        <MenuItem value="2">6 Months</MenuItem>
                        <MenuItem value="3">1 Year</MenuItem>
                    </Select>
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell>Doctor Name</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Call duration</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                CompletedAppointment?.length > 0 ?
                                    <TableBody>
                                        {
                                            CompletedAppointment?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.patientName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.doctorName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.date}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.time}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.callDuration}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button className={`outLineBtn ${styles.downloadPresBtn} ${item?.prescriptionURL?.endsWith('.pdf') ? '' : styles.noPrescriptionBtn}`} onClick={(e) => handleViewPrescription(e, item)}>{item?.prescriptionURL?.endsWith('.pdf') ? 'Prescription Sent' : 'No Prescription'} <span><img src={Images.RxImg} alt="Rx. image" /></span></button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
            <DocumentViewModal />
            <UploadLabReportModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
                handleFileChange={handleFileChange}
                title='Upload Lab Report'
                file={file}
            />
        </div>
    );
}
import React, { FormEvent, useState } from 'react'
import styles from "../assets/styles/login.module.css"
import { FormGroup, IconButton, InputAdornment, TextField } from '@mui/material'
import { HidePasswordIcon, ShowPasswordIcon } from '../utils/functions/Icons'
import logo from "../assets/images/login/logo.svg"
import logo2 from "../assets/images/layout/sidebarlogo.png"
import girlImg from "../assets/images/login/groupimg.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { changePasswordApi } from '../utils/api/services'
import { toast } from 'react-toastify'
import BtnLoader from '../components/common/BtnLoader'
interface LoginTypes {
    confirmPassword: string,
    password: string,
    showPassword: boolean,
    showConfirmPassword: boolean
}
const ChangePassword = () => {
    const [loginData, setLoginData] = useState<LoginTypes>({
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    })
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleTogglePasswordVisibility = (field: keyof typeof loginData) => {
        setLoginData({
            ...loginData,
            [field]: !loginData[field]
        });
    };
    const handleChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        setLoginData({
            ...loginData,
            [name]: value
        })
    }

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (loginData.password !== loginData.confirmPassword) {
                return;
            }
            setBtnLoading(true)
            const result = await changePasswordApi({
                email: location.state,
                newPassword: loginData.password
            });
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                setLoginData({
                    password: '',
                    confirmPassword: '',
                    showPassword: false,
                    showConfirmPassword: false,
                })
                navigate('/login')
            } else {
                setBtnLoading(false)
                toast.error(result.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <div className={styles.loginMain}>
               <div className={`${styles.logo} ${styles.showOnlyOnMobile}`}>
                <img src={logo2} alt="logo" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-7 gap-6 h-full">
                <section className={`col-span-4 ${styles.bannerSection}`}>
                    <div className={styles.logo}>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className={styles.girlImg}>
                        <img src={girlImg} alt="girl image" />
                    </div>
                </section>
                <section className={`col-span-3 ${styles.formSection}`}>
                    <div className={styles.formBox}>
                        <div className={styles.loginHead}>
                            <h4>Change Password</h4>
                        </div>
                        <form noValidate={false} onSubmit={handleLogin}>
                            <FormGroup className="customFormGroup">
                                <TextField
                                    type={loginData.showPassword ? 'text' : 'password'}
                                    className={`custumTextField ${styles.InputStyle}`}
                                    name="password"
                                    onChange={handleChangeLogin}
                                    required
                                    value={loginData.password}
                                    placeholder='Enter New Password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleTogglePasswordVisibility('showPassword')}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {loginData.showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup mt-5">
                                <TextField
                                    type={loginData.showConfirmPassword ? 'text' : 'password'}
                                    className={`custumTextField ${styles.InputStyle}`}
                                    name="confirmPassword"
                                    onChange={handleChangeLogin}
                                    required
                                    value={loginData.confirmPassword}
                                    placeholder='Confirm Password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleTogglePasswordVisibility('showConfirmPassword')}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {loginData.showConfirmPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    loginData.password !== loginData.confirmPassword &&
                                    <span className='errorMsgInput mt-1'>{loginData.password !== loginData.confirmPassword ? "Passwords do not match" : ""}</span>
                                }
                            </FormGroup>
                            <button
                                type='submit'
                                className='fillButton w-full mt-8'
                                disabled={btnLoading}
                            >
                                {
                                    btnLoading &&
                                    <BtnLoader />
                                }
                                LOGIN
                            </button>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ChangePassword